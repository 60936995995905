import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import LayoutWithSidebar from '../components/LayoutWithSidebar'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { NextSeo } from 'next-seo'
import CtaCard from '../components/home/CtaCard'
import PropertyList from '../components/home/PropertyList'
import WelcomeTitle from '../components/home/WelcomeTitle'

function HomePage() {
  const { t } = useTranslation('home')

  return (
    <>
      <NextSeo
        title={t('seo.title') || ''}
        description={t('seo.description') || ''}
      />
      <div className="space-y-3">
        <CtaCard />
        <PropertyList />
      </div>
    </>
  )
}

HomePage.getLayout = (page: ReactElement) => {
  return (
    <LayoutWithSidebar topbarComponent={<WelcomeTitle />}>
      {page}
    </LayoutWithSidebar>
  )
}

export default HomePage

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'home',
        'common',
        'originals/global',
      ])),
    },
  }
}
