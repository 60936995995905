import React from 'react'
import Card from '../Card'
import Image from '../Image'
import Button, { buttonHierarchy, buttonShape } from '../Button'
import { useTranslation } from 'next-i18next'
import routes from '../../config/routes'

export default function CtaCard() {
  const { t } = useTranslation('home')

  return (
    <Card className="flex w-full space-x-0 border border-neutralGray/50 bg-neutralWhite p-3 lg:space-x-12">
      <div className="relative hidden flex-grow items-center lg:flex">
        <Image
          dir="/img/icons/"
          fileName="house-sm-min"
          className="top-0 z-10 h-auto w-auto -rotate-6 scale-[110%] py-2"
          alt="house 3D icon"
          lazyLoading={false}
        />
      </div>
      <div className="flex w-full flex-col text-center lg:w-[80%] lg:text-left">
        <div className="mb-2 h-full sm:mb-6 xl:mb-0 xl:h-[70%]">
          <h1 className="text-2xl font-medium text-neutralBlack lg:text-4xl">
            {t('ctaCard.title')}
          </h1>
          <p className="hidden pt-1 text-sm text-neutralBlack/80 sm:block">
            {t('ctaCard.subtitle')}
          </p>
        </div>
        <div className="flex flex-grow">
          <div className="flex w-full flex-col">
            <div className="pb-3">
              <p className="text-xs font-bold text-neutralBlack/80">
                {t('ctaCard.readyCta')}
              </p>
            </div>
            <div className="flex-grow">
              <Button
                hierarchy={buttonHierarchy.primary}
                shape={buttonShape.rectangle}
                link={routes.properties}
                className="text-md inline-flex items-center rounded px-4 py-2 font-medium"
              >
                {t('ctaCard.exploreOriginals')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
